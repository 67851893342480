import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import partition from 'lodash/partition';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { CustomerQuery } from 'src/apollo/onlineOrdering';
import { reportErrorMessageWithData } from 'src/lib/js/clientError';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getReadyTimeChangeBucket, saveFulfillmentTime } from 'src/public/components/default_template/online_ordering/checkout/fulfillmentTimeUtils';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout, OrderError } from 'src/public/components/online_ordering/CheckoutContext';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useTimeBasedRules } from 'src/public/components/online_ordering/TimeBasedRuleContext';
import { CompletedOrder } from 'src/public/components/online_ordering/types';
import { Channel } from 'src/public/js/siteUtilities';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { useTempEventTracker } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { MarketingSubscriptionChannel, useMarketing } from 'public/components/online_ordering/MarketingContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { sumByField } from 'public/components/online_ordering/reducerUtils';

import { getFirstOrThirdParty } from './loyalty/LoyaltyUtils';
import { useRefreshToastCashAmount } from './payment/ToastCashPaymentToggle';

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

/**
 * Check if all required fields are present, and have values which are valid
 * @param customer - The customer object to check
 * @param countryCode - Rx country code, it will be undefined if FF is enabled
 * @returns true if all required fields are present and valid in the specified country, false otherwise
 * */
export const customerHasAllValidInfo = (customer: Customer, countryCode: CountryCode | undefined) => {
  return Boolean(customer?.firstName && customer?.lastName && customer?.email && customer?.phone)
    && countryCode
    ? isValidPhoneNumber(customer?.phone ?? '', countryCode) :
    isValidPhoneNumber(`+${customer?.phone}`); // we need to add + sign for guest phone numbers if not passing country
};


export const getCustomerInfo = (customer: Customer) => {
  const phoneNum = isValidPhoneNumber(`+${customer?.phone}`) ? `+${customer?.phone}` : '';

  return {
    yourInfoPhone: phoneNum,
    yourInfoEmail: customer?.email || '',
    yourInfoFirstName: customer?.firstName || '',
    yourInfoLastName: customer?.lastName || ''
  };
};

export const submitPhoneNumberImpl = async (
  phoneNumber: string,
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>,
  setSentCode: React.Dispatch<React.SetStateAction<boolean>>,
  setPasswordlessError: React.Dispatch<React.SetStateAction<string>>
) => {
  if(phoneNumber && isValidPhoneNumber(phoneNumber, 'US')) {
    setSentCode(true);
    if(!await passwordlessLogin(phoneNumber)) {
      setPasswordlessError('Error sending confirmation code');
      setSentCode(false);
    } else {
      setPasswordlessError('');
    }
  }
};

/**
 * Extract national phone number if phone number is in E.164 format
 * @param phoneNum
 * @param intlOoPhoneCountryDropDownFlag
 * @returns phone number
 * */
export const getNationalPhoneNumberForOrder = (phoneNum: string, intlOoPhoneCountryDropDownFlag: boolean) => {
  return intlOoPhoneCountryDropDownFlag && isValidPhoneNumber(phoneNum)
    ? parsePhoneNumber(phoneNum)?.nationalNumber
    : phoneNum.replace(/\D/g, '');
};

/**
 * Extract country calling code for order
 * @param phoneNum
 * @param countryCode
 * @param intlOoPhoneCountryDropDownFlag
 * @returns country calling code for phone number or undefined
 * */
export const getCallingCodeForOrder = (phoneNum: string, countryCode: string | undefined, intlOoPhoneCountryDropDownFlag: boolean) => {
  if(intlOoPhoneCountryDropDownFlag) {
    if(countryCode) {
      return countryCode;
    } else if(isValidPhoneNumber(phoneNum)) {
      return parsePhoneNumber(phoneNum).countryCallingCode;
    }
  }

  return undefined;
};

// Enums to standardize data values sent to the tracker

export enum AuthenticationStatus {
  Authenticated = 'Authenticated',
  Guest = 'Guest'
}

export enum AuthenticationSource {
  AccountCreation = 'Create a Toast account',
  AccountPage = 'Account page',
  UserNav = 'User nav',
  ExpressCheckout = 'Express checkout with Toast',
  GuestCheckout = 'Guest checkout page',
  LoyaltyCard = 'Loyalty card',
  LoyaltyCardOnConfirmationPage = 'Loyalty card on order confirmation page'
}

export function getAuthenticationStatus(customer: Customer | undefined) {
  return customer ? AuthenticationStatus.Authenticated : AuthenticationStatus.Guest;
}

// Returns a string indicating how the order was paid for
export const getPaymentOption = (
  paymentType: string | null,
  paymentOption: PaymentOption | null,
  giftCardAppliedAmount: number,
  orderTotal: number,
  isInternational: boolean
) => {
  if(giftCardAppliedAmount === orderTotal) return 'GC';
  if(paymentOption === PaymentOption.UponReceipt) return 'CASH';
  if(isInternational) return 'ADYEN';
  if(paymentOption === PaymentOption.PayNow) return paymentType;
  return null;
};


export const useHandleCompletedOrderCallback = () => {
  const tracker = useTracker();
  const history = useHistory();
  const { cartGuid, cart, clearCart, channel } = useCart();
  const { paymentOption, tipAmount, paymentType } = usePayment();
  const { giftCardAppliedAmount, orderTotal, setShowAdyenOverlay, saveNewAddress } = useCheckout();
  const { confirmationPath, ecommConfirmationPath } = useRestaurantRoutes();
  const { ooRestaurant, restaurant: { pixelsV2 } } = useRestaurant();
  const { rankedPromoOfferDiscounts } = useOffers();
  const { selectionsInCartWithTBRs, getItemLeadTime } = useTimeBasedRules();
  const { createMarketingSubscriptionRequest, subscribeToSmsMarketing, smsAccountEnabled, subscribeToEmailMarketing } = useMarketing();
  const userId = useExperimentUserId();
  const { ooToastCashSpend: ooToastCashSpendEnabled } = useFlags();
  const refreshToastCashBalance = useRefreshToastCashAmount();
  const isIntlRestaurant = useIsIntlRestaurant();
  const { savedAddressUsed } = useDelivery();


  return useCallback((completedOrder: CompletedOrder) => {
    const { customerV3 } = completedOrder;

    if(completedOrder?.guid) {
      if(cart?.order) {
        // It's safe to assume at this point that if the item has a lead time, its TBR is a lead time rule, and if it doesn't, its TBR is a preorder rule
        const [selectionsWithLeadTimeRules, selectionsWithPreorderRules] = partition(selectionsInCartWithTBRs, s => getItemLeadTime(s.itemGuid) !== undefined);
        tracker.trackConversion(cart, cartGuid!, pixelsV2, ooRestaurant?.adsManagerRestaurantConversionsData ?? null );
        tracker.track('Order placed', {
          cartSource: cart.cartSource,
          restaurantGuid: cart!.restaurant!.guid,
          diningOption: cart.diningOptionBehavior,
          fulfillmentTime: cart.fulfillmentType,
          numItems: cart.order.numberOfSelections,
          subtotal: cart.order.preDiscountItemsSubtotal,
          tax: cart.order.taxV2,
          deliveryChargeTotal: cart.order.deliveryServiceCharges,
          gratuityServiceCharges: cart.order.gratuityServiceCharges,
          processingServiceCharges: cart.order.processingServiceCharges,
          nonDeliveryNonGratuityNonUbpServiceCharges: cart.order.nonDeliveryNonGratuityNonUbpServiceCharges,
          discounts: cart.order.discountsTotal,
          total: cart.order?.totalV2,
          paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal, isIntlRestaurant),
          giftCardApplied: giftCardAppliedAmount > 0,
          tipAmount,
          redemptionsAppliedToCheck: completedOrder.discounts.loyaltyDiscounts,
          firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName),
          leadTimeRuleItemCount: sumByField('quantity')(selectionsWithLeadTimeRules),
          leadTimeRuleTotalPrice: sumByField('price')(selectionsWithLeadTimeRules),
          preorderRuleItemCount: sumByField('quantity')(selectionsWithPreorderRules),
          preorderRuleTotalPrice: sumByField('price')(selectionsWithPreorderRules),
          useExperimentUserId: userId,
          smsAccountEnabled: smsAccountEnabled,
          smsConsentProvided: subscribeToSmsMarketing,
          smsSubscriptionIncompleteData: smsAccountEnabled && subscribeToSmsMarketing && (!customerV3 || !customerV3?.phone),
          emailConsentProvided: subscribeToEmailMarketing,
          numOffersPresent: rankedPromoOfferDiscounts?.length ?? 0,
          numPromoCodesUsed: cart.order.discounts.restaurantDiscount?.promoCode ? 1 : 0,
          deliveryProvider: cart.deliveryProviderInfo?.provider,
          saveNewAddress,
          savedAddressUsed: cart?.diningOptionBehavior === DiningOptionBehavior.Delivery ? savedAddressUsed : undefined,
          isTds: !!completedOrder.deliveryServiceInfo,
          orderGuid: completedOrder.guid,
          readyTimeChangeBucket: getReadyTimeChangeBucket(completedOrder, cartGuid, true)
        });
      }

      // IF SMS CHECKBOX CHECKED, SUBSCRIBE TO SMS
      if(subscribeToSmsMarketing && customerV3 && customerV3?.phone) {
        createMarketingSubscriptionRequest({
          customerInput: customerV3.phone,
          firstName: customerV3.firstName || '',
          lastName: customerV3.lastName || '',
          channel: MarketingSubscriptionChannel.SMS
        });
      }

      // IF EMAIL CHECKBOX CHECKED, SUBSCRIBE TO EMAIL
      if(subscribeToEmailMarketing && customerV3 && customerV3?.email) {
        createMarketingSubscriptionRequest({
          customerInput: customerV3.email,
          firstName: customerV3.firstName || '',
          lastName: customerV3.lastName || '',
          channel: MarketingSubscriptionChannel.EMAIL
        });
      }

      // We save the fulfillment time to local storage here so that it is available on order confirmation based on the order guid because the cart is cleared
      saveFulfillmentTime(cart, completedOrder.guid);

      clearCart();
      setShowAdyenOverlay(false);
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      if(ooToastCashSpendEnabled) {
        refreshToastCashBalance();
      }
      history.push(channel === Channel.ECOMM && ecommConfirmationPath ? ecommConfirmationPath : confirmationPath, { completedOrder });
    }
  }, [cart,
    subscribeToSmsMarketing,
    subscribeToEmailMarketing,
    clearCart,
    setShowAdyenOverlay,
    history,
    confirmationPath,
    ecommConfirmationPath,
    channel,
    selectionsInCartWithTBRs,
    tracker,
    cartGuid,
    pixelsV2,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    tipAmount,
    ooRestaurant?.loyaltyConfig?.programName,
    ooRestaurant?.adsManagerRestaurantConversionsData,
    userId,
    smsAccountEnabled,
    getItemLeadTime,
    createMarketingSubscriptionRequest,
    rankedPromoOfferDiscounts,
    ooToastCashSpendEnabled,
    refreshToastCashBalance,
    isIntlRestaurant,
    saveNewAddress,
    savedAddressUsed]);
};

export const useHandleValidationError = () => {
  const { track } = useTempEventTracker();
  const { setOrderError } = useCheckout();

  const onError = useCallback((orderError: OrderError) => {
    setOrderError(orderError);
    track('temp_SPI_precheckout_cart_validation_failed', orderError);
    reportErrorMessageWithData('Invalid cart', orderError);
  }, [setOrderError, track]);

  return onError;
};
