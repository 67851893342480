const config = {};

// Env
config.environment = 'production';
config.apiVersion = 1;

// Company name
config.companyName = 'Toast';

// Trusted hostname
config.trustedHosts = ['www.toasttab.com', 'toasttab.com', 'prod.nv5.toast.ventures'];

config.serviceName = 'sites-web';

config.giaAuth = false;

config.reCaptchaEnterprise = {};
config.reCaptchaEnterprise.siteKey = '6LfD-H8mAAAAAK-gw-dLyIgoh1TpBg2VuSZi5SJA';

// Configuration namespaces
config.server = {};
config.resources = {};
config.datadog = {};
config.sentry = {};
config.owg = {};
config.sift = {};
config.toast = {};
config.responseCache = {};

config.toast.clientId = 'encrypted:prod/oauth/sites-web:client_id';
config.toast.clientSecret = 'encrypted:prod/oauth/sites-web:client_secret';

config.gMapsAPIKey = 'AIzaSyDsJlw0WSpry4Kig6-1LfjR0vo89NYZudM';
config.gPlacesAPIKey = 'AIzaSyDx-1bQhTqW3DUEPNMTk-yegz1c9YQdzUs';
config.ldClientId = '59e4bfdd6292ac0ac9174386';
config.ldSdkKey = 'sdk-1251e53d-b792-47b7-b5de-118f8da99497';
config.toastDopplerSecret = 'encrypted:external/prod/sites-web-doppler-token';
config.amplitudeApiKey = 'beee74d9d5b5b3f679127e4d272881ee';
config.ipapikey = 'c053e8b85c68fb776fa75d5f1855bd93';
config.giftCardRecaptchaSiteKey = '6LdI_fcpAAAAANCTIma-FhR0C3EBGvMV-CYuhozK';

// Resources configuration
config.resources.serverQueryTimeoutMs = 10_000;
config.resources.clientQueryTimeoutMs = 30_000;
config.resources.apiHost = typeof window === 'undefined' ? 'http://localhost:36867' : 'https://ws-api.toasttab.com';
config.resources.apiEndpoint = `${config.resources.apiHost}/sites-api/v1/api`;
config.resources.mapEndpoint = 'https://ws-api.toasttab.com/sites-api/v1/api';
config.resources.federatedProxyHost = `${config.resources.apiHost}/do-federated-gateway/v1/${typeof window === 'undefined' ? 'internal/' : ''}graphql`;
config.resources.webpackDevServer = false;
config.resources.publicAssetURL = 'https://d28f3w0x9i80nq.cloudfront.net/';
config.resources.imagePrefix = 'https://d1w7312wesee68.cloudfront.net/';
config.resources.publicAppURL = `${config.resources.publicAssetURL}app`;
config.resources.ooAssetURL = null; // Production image urls work out of the box, and no hostname substitution is needed
config.resources.tlAssetUrl = 'https://d2s742iet3d3t1.cloudfront.net/';
config.resources.checkoutHost = 'https://www.toasttab.com/';
config.resources.toastLocalHost = 'www.toasttab.com';
config.resources.toastOrderHost = 'order.toasttab.com';
config.resources.schemaHostBlocklist = [config.resources.toastLocalHost];
config.resources.exposeSitemap = true;
config.resources.sitemapGenerationBatchSize = 10;
config.resources.toastwebBaseUri = 'https://www.toasttab.com/';
config.resources.s3Bucket = 'toast-sites-resources-prod';
config.resources.adyenURL = 'https://*.adyen.com';
config.resources.semiPaymentGatewayURL = 'https://ws-api.toasttab.com/semi-payments-gateway';
config.resources.googlePayURL = 'https://pay.google.com/';
config.resources.googleURL = 'https://google.com/';
config.resources.spiURL = 'https://payments.toasttab.com/assets/loader.js';
// Overrides the default config to disable Google Pay
config.resources.paymentMethodConfigId = '874631f2-eb32-4287-8c6b-d5c0eb6bbff6';

config.resources.trustedApiHosts = [config.resources.apiHost];

// Server configuration
config.server.protocol = 'https';
config.server.httpPort = 8443;
config.server.webpackDevServerPort = 8000;
config.server.host = 'ws-api.toasttab.com';
config.server.altHosts = [];
config.server.fullHost = config.server.host;
config.server.allowableResourceHosts = {};

config.resources.sitesHost = `${config.server.protocol}://${config.server.host}`;

config.toastApi = {
  apiRoot: config.resources.apiHost,
  clientId: process.env.TOAST_CLIENT_ID || '',
  clientSecret: process.env.TOAST_CLIENT_SECRET || ''
};

config.server.allowableResourceHosts.script = [
  'https://browser.sentry-cdn.com',
  'https://fonts.googleapis.com',
  'https://fonts.gstatic.com',
  'https://maps.googleapis.com',
  'https://www.googletagmanager.com',
  'https://ajax.cloudflare.com',
  'https://www.google.com/recaptcha/',
  'https://www.gstatic.com/recaptcha/',
  'https://cdn.userway.org/',
  'https://api.userway.org/',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/',
  'https://cdn.sift.com/s.js',
  'https://cdn.cookielaw.org/'
];
config.server.allowableResourceHosts.pixels = [
  'www.toasttab.com',
  'https://www.google-analytics.com',
  'analytics.google.com',
  'www.googleadservices.com',
  '*.doubleclick.net',
  '*.facebook.net',
  '*.hotjar.com',
  'wss://*.hotjar.com',
  'https://www.facebook.com/signals/iwl.js',
  'https://payments.toasttab.com',
  'https://payments.sandbox.eng.toasttab.com' // A bug with spi loads the manifest file from sandbox, even in prod
];
config.server.allowableResourceHosts.connect = [
  'https://www.google.com',
  'https://maps.googleapis.com',
  'https://www.google-analytics.com',
  'https://www.googletagmanager.com',
  'https://graph.facebook.com/',
  'https://events.launchdarkly.com',
  'https://app.launchdarkly.com',
  'https://clientstream.launchdarkly.com',
  'https://api2.amplitude.com/2/httpapi',
  'https://cdn.userway.org/',
  'https://api.userway.org/',
  'https://api.ipapi.com/',
  'https://cdn.cookielaw.org/',
  'https://geolocation.onetrust.com/',
  'https://privacyportal-de.onetrust.com/'
];
config.server.allowableResourceHosts.style = [
  'https://unpkg.com',
  'https://fonts.googleapis.com',
  'https://use.typekit.net',
  'https://p.typekit.net',
  'https://cdn.userway.org/',
  'https://api.userway.org/'
];
config.server.allowableResourceHosts.frameSrc = [
  'https://cdn.userway.org/',
  'https://business.untappd.com'
];

// Sentry
config.sentry.host = 'o37442.ingest.sentry.io';
config.sentry.dsn = `https://6d67e6de629842998b120b40164bd25b@${config.sentry.host}/6180756`;
config.sentry.env = 'production';

// Order with Google Redirects
config.owg.partnerId = '20001125';
config.owg.ooPartnerId = '20003218';
config.owg.conversionHost = 'https://www.google.com/maps/conversion/collect';

// Toast DataDog config
config.datadog.host = process.env.DATADOG_HOST || '';
config.datadog.port = 8125;
config.datadog.globalTags = [
  'toast_service_name:sites-web', `toast_service_revision:${process.env.toast_service_revision}`, 'toast_environment:prod', `toast_deployment:${process.env.toast_deployment}`
];
config.datadog.rum = {
  enabled: true,
  clientToken: 'pub42715a09f9a12b1dd63162ff54b9b2b3',
  applicationId: 'b8973e2d-9c80-4e55-93a3-3c9e994702ea',
  samplingRate: 1,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  host: ['https://browser-intake-datadoghq.com', 'www.datadoghq-browser-agent.com'],
  version: `${process.env.toast_service_revision}`
};

config.oneTrust = {
  ooScript: '27b2ab47-55c6-4f95-8752-c3c29f5cc956',
  websitesScript: '' // this needs to be configured and published in the OneTrust dashboard '0194d08b-9421-71ab-b9a7-81b860e017fc'
};
config.sift.beaconKey = '3ae2d35896';

// Cache Control
config.cache = {
  cacheAge: 60, // Intermediate cache control
  browserAge: 120, // Browser cache control
  browserExpiresMinutes: 2 // expiration management
};
config.enableCompression = false;

config.responseCache.url = 'redis://prod-sites-web-cache.glzywl.ng.0001.use1.cache.amazonaws.com';

module.exports = config;
