import React from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';

import { DiningOptionBehavior, Selection } from 'src/apollo/onlineOrdering';
import { flattenModifiers } from 'src/public/components/default_template/online_ordering/order/OrderItemsUtil';
import { CartErrorType } from 'src/public/components/online_ordering/CartContext';
import { use3pdConfig } from 'src/public/components/online_ordering/FulfillmentContext';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import ErrorNotice from 'shared/components/common/error_notice';

import {
  CartSelections,
  CartSelection,
  CompletedOrderSelections,
  CompletedOrderSelection
} from 'public/components/online_ordering/types';

import ItemPrice from './ItemPrice';
import TaxesAndFees from './TaxesAndFees';

export const ECOMM_ITEM_NAME = 'Toast E-Commerce Shipping Fee';

const getOrderItemLabelId = (selection: CompletedOrderSelection | CartSelection) => {
  const anySelection = selection as Selection;
  return `orderItemSelection-${normalizeHtmlId(anySelection.guid || '')}`;
};

export const OrderItem = ({ selection, itemActions }: { selection: CompletedOrderSelection | CartSelection, itemActions?: React.ReactElement }) => {
  const labelledById = getOrderItemLabelId(selection);

  if(!selection) {
    return null;
  }

  const fractionalQuantity = selection.usesFractionalQuantity && `${selection.fractionalQuantity.quantity.toFixed(2)} ${selection.fractionalQuantity.unitOfMeasure.toLowerCase()}`;
  const isItemDiscounted = (selection.price || 0) < (selection.preDiscountPrice || 0);
  const discountedPrice = selection.price === 0 ? 'Free' : <FormattedPrice value={selection.price || 0} />;
  const flattennedModifiers = selection.modifiers ? flattenModifiers(selection.modifiers) : [];
  const hasQuantitiesOverOne = flattennedModifiers.some(modifier => modifier.quantity && modifier.quantity > 1);

  return (
    <div className="item" data-testid="orderItem">
      <div className="row details" id={labelledById}>
        <div className="quantity">
          {selection.quantity || 1}
        </div>
        <div className="cell">
          <h3 className="name">{selection.name}</h3>
          {selection.usesFractionalQuantity ? <div className="fractional">{fractionalQuantity}</div> : null}
          <div className="mods">
            {selection.modifiers
              ? flattennedModifiers.map((modifier, index) =>
                <div style={{ paddingLeft: `${modifier.level * 8}px` }}
                  className="mod"
                  data-testid={`mod-${index}`}
                  key={`mod${index}`}>
                  {hasQuantitiesOverOne && <span className="quantity">{modifier.quantity ?? 1}</span>}{modifier?.name}
                </div>)
              : null}
          </div>
        </div>
        <div className="rightColumn">
          <span data-testid="price" className={classnames('price', { ['discountedPrice']: isItemDiscounted })}><FormattedPrice value={selection.preDiscountPrice || 0} /></span>
          {isItemDiscounted && <span className="price">{discountedPrice}</span> }
        </div>
      </div>
      {
        Boolean(itemActions) &&
          <div className={classnames('row', 'details')} role="group">
            <div className="emptyCell">
                &nbsp;
            </div>
            <div className="actions">
              {itemActions}
            </div>
          </div>
      }
    </div>
  );
};

type OrderPriceProps = {
  loading: boolean;
  subtotal?: number | null;
  discountsTotal?: number;
  diningOptionBehavior?: string;
  deliveryFee?: number | null;
  gratuityServiceCharges: number;
  processingServiceCharges: number;
  nonDeliveryNonGratuityNonUbpServiceCharges: number;
  tax: number;
  tip?: number;
  fundraisingAmount?: number | null;
  giftCardAppliedAmount?: number;
  surchargeAmount?: number;
  surchargeTaxAmount?: number;
  enableTooltip?: boolean;
  shipping?: number;
  shippingCalculatedAtCheckout?: boolean;
};

export const OrderPrices = (props: OrderPriceProps) => {
  const { uses3pd } = use3pdConfig();
  const is3pdDelivery = uses3pd && props.diningOptionBehavior === DiningOptionBehavior.Delivery;
  return (
    <div className="prices">
      {props.shippingCalculatedAtCheckout ?
        <ItemPrice loading={props.loading} title="Shipping" calculatedAtCheckout /> :
        props.shipping ? <ItemPrice loading={props.loading} title="Shipping" amount={props.shipping} /> : null}
      <ItemPrice loading={props.loading} title="Subtotal" amount={props.subtotal} />
      {props.discountsTotal ? <ItemPrice loading={props.loading} title="Discounts" amount={props.discountsTotal} negative={true} enableTooltip={props.enableTooltip} /> : null}
      {props.diningOptionBehavior === DiningOptionBehavior.Delivery && <ItemPrice loading={props.loading} title="Delivery" amount={props.deliveryFee} />}
      <TaxesAndFees
        loading={props.loading}
        tax={props.tax}
        gratuityServiceCharges={props.gratuityServiceCharges}
        processingServiceCharges={props.processingServiceCharges}
        nonDeliveryNonGratuityNonUbpServiceCharges={props.nonDeliveryNonGratuityNonUbpServiceCharges} />
      {props.surchargeAmount ? <ItemPrice loading={props.loading} title="Credit Card Surcharge" amount={props.surchargeAmount} /> : null}
      {props.surchargeTaxAmount ? <ItemPrice loading={props.loading} title="Surcharge Tax" amount={props.surchargeTaxAmount} /> : null}
      {props.tip ? <ItemPrice loading={props.loading} title={is3pdDelivery ? 'Driver tip' : 'Tip'} amount={props.tip} /> : null}
      {props.fundraisingAmount ? <ItemPrice loading={props.loading} title="Support & Give" amount={props.fundraisingAmount} /> : null}
      {props.giftCardAppliedAmount ? <ItemPrice loading={props.loading} title="Gift Card" amount={props.giftCardAppliedAmount} negative={true} /> : null}
    </div>
  );
};

interface ItemListProps {
  selections?: CompletedOrderSelections | CartSelections | null;
  ItemComponent?: any;
  itemProps?: any;
}

const ItemList = ( { selections, ItemComponent, itemProps }: ItemListProps) => {
  return (
    <ul className="itemList">
      {selections?.filter(selection => selection?.name !== ECOMM_ITEM_NAME)
        .map((selection, index) => {
          const labelledById = getOrderItemLabelId(selection);
          return (
            <li key={`selection${index}`} aria-labelledby={labelledById}>
              <ItemComponent selection={selection} {...itemProps} />
            </li>
          );
        })}
    </ul>
  );
};

type OrderItemsProps = {
  loading: boolean;
  error?: CartErrorType | null;
  selections?: CompletedOrderSelections | CartSelections | null;
  hasItems?: boolean;
  ItemComponent?: any;
  itemProps?: any;
  noItemsComponent?: React.ReactElement;
};

export const OrderItems = (props: OrderItemsProps) => {
  const hasItems = 'hasItems' in props ? props.hasItems : !!props.selections?.length;
  const ItemComponent = props.ItemComponent || OrderItem;

  return (
    <div className="items">
      {props.error?.kind === 'CartOutOfStockError' || props.error?.kind === 'CartError' ? <ErrorNotice expireAfterSeconds={5}>{props.error.message}</ErrorNotice> : null}
      {props.loading ?
        <>
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
        </> :
        null}
      {!props.loading
        ? hasItems
          ? <ItemList selections={props.selections} ItemComponent={ItemComponent} itemProps={props.itemProps}></ItemList>
          : props.noItemsComponent
        : null}
    </div>
  );
};

export default OrderItems;
